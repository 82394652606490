import '../../styles/homeandlifepage.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import bitmap_2 from '../../images/bitmap_2.png';
import Illustration from '../../images/illustration-bubble-tip.png';
import bitmap_1 from '../../images/images/bitmap-new.jpeg';
import girlwithcat from '../../images/images/group-3.png';
import PH_Image from '../../images/images/group-8.png';
import righttick from '../../images/images/icon-tick-green.svg';
import safecologo from '../../images/safecohome.png';

const SafecoAutoPage = ( { data, parent } ) => {

  return (
    <Layout header={'menu'}>
      <SEO
        title="Auto insurance by Safeco, Safeco, Best Life Insurance Policy, Life insurance quotes online, Online insurance"
        canonicalUrl='https://www.getmybubble.com/safecoauto/'
        description="Auto insurance by Safeco, Safeco, Auto insurance quotes online, Online  home insurance policy"
        keywords="Auto insurance by Safeco, Safeco, Auto insurance quotes online, Online  home insurance policy"
      />

      {/* <Menu /> */}

      <div className="flexColumn carrierCommonPage">
        <div className="container-fluid carrier-plan-container_1">
          {/* carrier-plan-container */}

          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="padding-left">
                  <img src={safecologo} alt="logo" width="200"/>

                  <h1 className="carrier-plan-heading">Auto insurance</h1>

                  <p className="carrier-plan-section1-para">
                  With Safeco's broad array of coverage options, you can independently tailor your policy to fit your needs, and your budget.
                  </p>

                  <div className="flexRow align-btns ml-1">
                    <Link to="/">
                      <button type="button" className="orangeBtn mr-3">
                      Get a quote now
                      </button>
                    </Link>

                    <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                      <button type="button" className="explore">
                        Explore
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 sbli-image" style={{ textAlign: 'center' }}>
                <img src="https://cdn.getmybubble.com/images/corporate-sites/auto-insurance.svg" className="carrier-right-img-mv mt-4 mt-sm-4 mt-md-4 mt-lg-0 mb-0 auto-image" alt=""/>
              </div>
            </div>
          </div>

        </div>

        {/*section 2*/}

        <div id="start-exploring" className="container-fluid" style={{ marginTop: '80px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 costom-padding19">
              <h3 className="stillwater-title1">
                Why to choose Safeco Auto Insurance?
              </h3>

              <p className="stillwater-para1">
                With Safeco's broad array of coverage options, you can independently tailor your policy to fit your needs, and your budget.
              </p>

            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        {/* Product Highlights Section */}

        <div className="container-fluid">
          <div className="row" style={{ marginTop: '30px' }}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-2"></div>

                <div className="col-lg-9 product-container-alignment costom-padding19">
                  <div className="stillwater-product-container">

                    <div className="align-horizontal-center">
                      <img src={PH_Image} className="ph-desktop-view" alt="phdesk"/>

                      <h3
                        className="ph-title ph-desktop-view">
                        Safeco's safe driving rewards
                      </h3>

                      <h3
                        className="ph-title ph-mobile-view">
                        Safeco's safe driving rewards
                      </h3>

                      <img src={PH_Image} className="ph-mobile-view" alt="phmob"/>
                    </div>

                    <div
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' }}></img>

                      <div className="ph-item-style pl-3">
                        Accident Forgiveness
                        The first accident on your policy is waived after a set number of years with Safeco without an at-fault collision or violation.
                      </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Claims-Free Cash Back
                        If you were claims-free over a six-month review period, Safeco will mail you a check for 2.5% of your policy premium (up to 5% each year) — automatically.
                      </div>

                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Diminishing Deductible
                        The collision deductible will de
                        crease at every renewal if there are no claims filed against your policy. The deductible will continue to decrease for up to five years or until the $500 maximum is reached.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Low Mileage Discount
                        Safeco's low mileage discount is a vehicle-level discount, which allows drivers who drive less to save up to 20% on their auto insurance, depending on their age and the annual miles they drive. We expect that nearly half of drivers who qualify will receive a discount of 10% or more.
                      </div></div>


                  </div>

                </div>

                <div className="col-lg-1"></div>
              </div>
            </div>

            <div className="col-lg-6 carrier-middle-container carrier-middle-container-mv">

              <h4 className="stillwater-col2-title">Standard Coverages:</h4>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Liability</b>

                  <p>If someone is hurt or their property is damaged in an accident, Safeco will help pay for covered damages and expenses, including court costs if you are sued.</p>
                </li>

                <li>
                  <b>Medical Payments</b>

                  <p>Safeco will cover accident-related medical expenses up to the policy limits for anyone injured while in your car, regardless of who's at fault.</p>
                </li>

                <li>
                  <b>Uninsured/Underinsured Motorists</b>

                  <p>If you're injured in an accident caused by someone with minimal or no insurance, Safeco will protect you and any passengers in your vehicle.</p>
                </li>

              </ul>

              <h4 className="stillwater-col2-title">Optional Coverages:</h4>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Collision</b>

                  <p>You get in an old-fashioned fender bender with another vehicle, or collide with another object on the road.</p>
                </li>

                <li>
                  <b>Comprehensive</b>

                  <p>You wake up to find your car has been stolen. Don't sweat it — Comprehensive will pay for the theft.</p>
                </li>

                <li>
                  <b>Guaranteed Repair Network</b>

                  <p>Select a Guaranteed Repair Network shop for your car, get an estimate, get it fixed, and get back on the road. If anything goes wrong from there, you're covered with Safeco's President's Guarantee.</p>
                </li>

                <li>
                  <b>New Vehicle Replacement</b>

                  <p>If you're the original owner of a car less than one year old that has been totaled or stolen, we'll pay you the value of a comparable new car.4</p>
                </li>

                <li>
                  <b>Rental Car Reimbursement</b>

                  <p>Get help paying for the cost of renting a car while yours is in the shop being repaired after a covered loss.5</p>
                </li>

                <li>
                  <b>24-Hour Roadside Assistance</b>

                  <p>Covers towing to the nearest qualified repair shop and other basic emergency services. Safeco Roadside Assistance will help you change a flat tire, deliver gas, oil, or other essential fluids, and send out a locksmith if you're locked out of your car.6</p>
                </li>

                <li>
                  <b>RideSharing Coverage</b>

                  <p>Safeco's new RideSharing CoverageTM is designed to provide coverage during the specific period of time when the driver has logged into the transportation network company's application and is looking for a passenger, but has not accepted a request for transportation and no passenger is occupying the vehicle. During this time, the driver is not covered by the Safeco Auto policy, and only minimally if at all by the transportation network company's policy.</p>
                </li>

              </ul>


            </div>
          </div>
        </div>

        <div className="container stillwater-card-container">
          <div className="row">
            <div className="col-lg-3">
            </div>

            <div className="col-lg-6">
              <h3 className="stillwater-title1">When it comes to protecting your assets, we make the grade.</h3>

              <div className="card-deck align-stillwater-cards">

                <div className="row">
                  <div className="col-md-12 col-lg-12 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_1} alt="bitmap"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">Has the financial strength and capability to guarantee our customers' polices and keep them secure.</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-lg-6 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_2} alt="bitmap"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">Proven track record of predicting financial stability in the insurance industry and substantiates</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
          </div>
        </div>

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which plan is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>
      </div>
    </Layout> );
};

export default SafecoAutoPage;
